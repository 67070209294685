import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  formatISODate,
  FORMATTED_PHONE_NUMBER,
  getIllnessEffectiveDate,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, FAQSet, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './checkout-faq.module.css';

export default function CheckoutFAQ({ policyState, vetName, isPrevSelected }) {
  const canICancelMyInsurance = {
    question: <span>Can I cancel my Pumpkin Pet Insurance Plan?</span>,
    answer: (
      <>
        <Body2>
          {policyState === 'NY' ? (
            <>
              Yes, you can cancel your Pumpkin Pet Insurance plan any time during
              your pet's year-long policy period and get a prorated refund for the
              remaining days in the month.
            </>
          ) : (
            <>
              Yes, you can cancel your Pumpkin Pet Insurance plan. Cancel in the
              first 15 days and get a full refund as long as you have not yet filed
              any covered claims. If you cancel after 15 days and/or have already
              filed a covered claim, you’ll get a prorated refund for the remaining
              days in the month.
            </>
          )}
        </Body2>
        <Body2>
          See{' '}
          <Link
            href={`${WWW_URL}/insurancepolicy`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              sample insurance policy
            </span>
          </Link>{' '}
          for full terms.
        </Body2>
      </>
    ),
  };

  const canICancelPEP = {
    question: <span>Can I cancel my Preventive Essentials?</span>,

    answer: (
      <>
        <Body2>
          Yes, you can cancel your Preventive Essentials at any time. If you have not
          received a refund for a Preventive Essentials Claim, cancel in the first 30
          days and get a full refund. Cancel after 30 days and get a prorated refund
          for the remaining days in the month.
        </Body2>

        <Body2>
          If you have received a refund for one or more Preventive Essential claims,
          cancel any time during the year term of your Customer Agreement, and
          Pumpkin will charge your card for an outstanding balance equal to the
          difference of the amount paid back to you for your claim(s) and the amount
          paid by you in monthly fees this term.
        </Body2>

        <Body2>
          See{' '}
          <Link
            href={`${WWW_URL}/customeragreement`}
            rel='noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              Preventive Essentials Customer Agreement
            </span>
          </Link>{' '}
          for full terms.
        </Body2>
      </>
    ),
  };

  const isMyVetsCareCovered = {
    question: <span>Is my vet's care covered?</span>,
    answer: (
      <>
        <Body2>
          Yes! Unlike a human health insurance HMO, your Pumpkin Pet Insurance plan's
          coverage isn't limited to a "network" of health care providers. Since you
          get paid back directly, your pet is free to receive treatment from any
          licensed veterinarian, specialist, emergency clinic or hospital you choose
          in the U.S. or Canada!
          {vetName ? (
            <> That includes your current veterinarian at {vetName}.</>
          ) : null}
        </Body2>
      </>
    ),
  };

  const isSpayAndNeuterCovered = {
    question: <span>Is spay {'&'} neuter covered?</span>,
    answer: (
      <>
        <Body2>
          Nope. Since pet insurance is "accident {'&'} illness insurance," Pumpkin
          plans won't cover elective procedures performed for general wellness
          maintenance like spaying or neutering.
        </Body2>

        <Body2>
          For a full list of what Pumpkin insurance plans do and don't cover,
          checkout out a{' '}
          <Link
            href={`${WWW_URL}/insurancepolicy`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              sample policy.
            </span>
          </Link>
        </Body2>
      </>
    ),
  };

  const waitingPeriodForInsurance = {
    question: <span>Is there a waiting period for insurance?</span>,

    answer: (
      <>
        <Body2>
          Yes. There is a 14-day waiting period before your pet's insurance coverage
          kicks in for all accidents {'&'} illnesses, and it starts the day after you
          enroll.
        </Body2>

        <Body2>
          So if you purchase a Pumpkin Pet Insurance plan today on{' '}
          {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}, your 14-day
          waiting period would start tomorrow and end on {getIllnessEffectiveDate()},
          the day your pet's insurance coverage would officially start.
        </Body2>
      </>
    ),
  };

  const preExistingCondition = {
    question: <span>What qualifies as a pre-existing condition?</span>,

    answer: (
      <>
        <Body2>
          If your pet has a condition that occured or showed symptoms before coverage
          starts or during the 14-day waiting period, it's considered "pre-existing"
          and won't be covered by your Pumpkin insurance plan - even if the condition
          has not been formally diagnosed by your vet.
        </Body2>

        <Body2>
          However, if the pre-existing condition is curable (e.g., UTI, paw
          fracture), was cured, and has been free of symptoms and treatment for 180
          days, your Pumpkin plan would cover it if it occurs again in the future,
          with the exception of knee {'&'} hind leg ligament conditions. If a knee or
          hind leg ligament condition occurs before the coverage start date or during
          the waiting period, any future ones won't be covered.
        </Body2>

        <Body2>
          All that said, having an incurable pre-existing condition, or a knee or
          hind leg ligament condition, doesn't mean you shouldn't join the Pumpkin
          pack! You can always enroll your pet and get covered for new conditions
          that develop down the road.
        </Body2>
      </>
    ),
  };

  const allQuestions = [
    canICancelMyInsurance,
    isPrevSelected ? canICancelPEP : null,
    isMyVetsCareCovered,
    isSpayAndNeuterCovered,
    policyState === 'ME' ? null : waitingPeriodForInsurance,
    preExistingCondition,
  ].filter(question => question);

  return (
    <>
      <h3>More Info</h3>
      <FAQSet questionSet={allQuestions} />
    </>
  );
}
