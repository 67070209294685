import { matchPath } from 'react-router';

import Paths from '../paths';

const GET_QUOTE = `Get Quote |`;

const title = {
  [Paths.EmbarkHello]: `${GET_QUOTE} Hello Embark`,
  [Paths.Intro]: `${GET_QUOTE} Intro`,
  [Paths.PetInfo]: `${GET_QUOTE} Pet Info`,
  [Paths.PetQuestions]: `${GET_QUOTE} Pet Questions`,
  [Paths.QuoteFlow]: 'Get Quote',
  [Paths.Register]: `${GET_QUOTE} Register`,
  [Paths.Checkout]: 'Checkout',
  [Paths.CheckoutDeepLink]: 'Checkout',
  [Paths.CheckYourEmail]: 'Purchase Success | Check your email',
  [Paths.PlanSelection]: 'Plan Selection',
  [Paths.PlanSelectionDeepLink]: 'Plan Selection Deep Link',
};

export default function getPageTitle(pathname) {
  if (matchPath(pathname, { path: Paths.PlanSelectionDeepLink, exact: true })) {
    return title[Paths.PlanSelectionDeepLink];
  } else if (matchPath(pathname, { path: Paths.CheckoutDeepLink, exact: true })) {
    return title[Paths.CheckoutDeepLink];
  } else {
    return (
      title[pathname] || 'Pumpkin® Pet Insurance | The best treat is a healthy life™'
    );
  }
}
