import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Store from 'store';

import {
  analyticsTrack,
  getTrackedCustomProps,
  QUOTE_FLOW,
} from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import { patchIdentity } from '@pumpkincare/identity';
import { setMarketingAttributionProperty } from '@pumpkincare/marketing';
import {
  getQuoteId,
  getQuotePets,
  QUOTE_ID_COOKIE_NAME,
  transformFinalize,
  useFinalize,
  useFinalizeAdd,
  useQuote,
} from '@pumpkincare/quotes';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  currentISODate,
  DUPLICATE_PET_ERROR,
  GENERIC_PAYMENT_ERROR,
  getCookie,
  getIsLoggedIn,
  ITERABLE_CAMPAIGN_ID,
  ITERABLE_TEMPLATE_ID,
  navigateTo,
  removeCookie,
  useBanners,
} from '@pumpkincare/shared';
import { getUserShippingAddress, useUssr } from '@pumpkincare/user';

import { getAppAgentId, Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity';
import { resetQuotes } from '../../../quotes';
import {
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../quotes/selectors';
import {
  useIsChargedAnnuallyValue,
  usePaperLessValue,
  useTotalsValue,
} from '../checkout-provider';

export default function useSubmitCheckout() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const dispatch = useDispatch();
  const agentId = useSelector(getAppAgentId);
  const billingAddress = useSelector(getQuotesBillingAddress);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const isShippingSameAsBilling = useSelector(getShippingSameAsBilling);
  const identityId = useSelector(getIdentityId);

  const { addBanner, removeAllBanners } = useBanners();
  const { grandTotal } = useTotalsValue();
  const isChargedAnnually = useIsChargedAnnuallyValue();
  const { termsVersion, isPaperLess } = usePaperLessValue();
  const [isLoading, setIsLoading] = useState(false);

  const { data: quoteData } = useQuote();
  const { data: userData } = useUssr();
  const pets = getQuotePets(quoteData);
  const quoteId = getQuoteId(quoteData);
  const userShippingAddress = getUserShippingAddress(userData);

  const { mutateAsync: finalize } = useFinalize();
  const { mutateAsync: finalizeAdd } = useFinalizeAdd();

  function submitCheckout() {
    const iterableCampaignId = getCookie(ITERABLE_CAMPAIGN_ID);
    const iterableTemplateId = getCookie(ITERABLE_TEMPLATE_ID);

    return stripe
      .createToken(elements.getElement(CardNumberElement))
      .then(payload => {
        analyticsTrack(
          {
            category: QUOTE_FLOW,
            event: 'Submit Payment',
            label: payload.error ? 'Failed' : 'Success',
          },
          getTrackedCustomProps()
        );

        return payload.error ? Promise.reject() : Promise.resolve(payload.token.id);
      })
      .then(stripeToken => {
        const postBody = transformFinalize(
          stripeToken,
          { billingAddress, shippingAddress, isShippingSameAsBilling },
          { agentId },
          isChargedAnnually,
          termsVersion,
          isPaperLess
        );

        return finalize({ quoteId, postBody });
      })
      .then(result => {
        const {
          token: { access_token },
          user,
        } = result;
        const { email } = user;

        analyticsTrack({
          event: 'Order Completed',
          email,
          total: parseFloat(grandTotal),
          campaignId: iterableCampaignId
            ? parseInt(iterableCampaignId)
            : iterableCampaignId,
          templateId: iterableTemplateId
            ? parseInt(iterableTemplateId)
            : iterableTemplateId,
          dataFields: {},
        });
        analyticsTrack(
          {
            category: 'Purchase Event',
            event: 'Finalize Success',
            label: `Policies: ${pets.length}, Plans: ${
              pets.filter(pet => !!pet.hasPrevent).length
            }`,
            created_at: currentISODate(),
          },
          getTrackedCustomProps()
        );

        setMarketingAttributionProperty({ isGoingToCheckoutSuccess: true });
        removeAllBanners();
        dispatch(resetQuotes());

        Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
        removeCookie(QUOTE_ID_COOKIE_NAME);

        history.push(
          Paths.Hdyhau +
            '?' +
            new URLSearchParams({ email, token: access_token }).toString()
        );
      });
  }

  function submitCheckoutApld() {
    return finalizeAdd({ quoteId, postBody: {} }).then(() => {
      Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
      removeCookie(QUOTE_ID_COOKIE_NAME);

      dispatch(resetQuotes());

      navigateTo(MC_URL);
    });
  }

  function submit() {
    setIsLoading(true);

    if (!userShippingAddress.id) {
      patchIdentity({
        id: identityId,
        city: shippingAddress.city.value,
        state_province: shippingAddress.state.value,
        zipcode: shippingAddress.zipcode.value,
        first_name: shippingAddress.firstName.value,
        last_name: shippingAddress.lastName.value,
      });
    }

    return (getIsLoggedIn() ? submitCheckoutApld() : submitCheckout())
      .catch(err => {
        addBanner(
          err?.response?.status === 409 ? DUPLICATE_PET_ERROR : GENERIC_PAYMENT_ERROR
        );

        throw err;
      })
      .finally(() => setIsLoading(false));
  }

  return { submit, isLoading };
}
