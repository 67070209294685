import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Store from 'store';

import { postIdentityHdyhau } from '@pumpkincare/identity';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  getMemberRegisterUrl,
  IDENTITY_LOCAL_STORAGE_KEY,
  navigateTo,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { customSignInToCognito, postUserVet } from '@pumpkincare/user';

import { Paths } from '../app-shell';

export default function useSubmitHdyhau(quoteVetId) {
  const history = useHistory();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const email = urlSearchParams.get('email');
  const token = urlSearchParams.get('token');

  const identityId = Store.get(IDENTITY_LOCAL_STORAGE_KEY);

  const [isLoading, setIsLoading] = useState(false);

  async function submit({
    vet: inputVet,
    hdyhauOptionSelected,
    hdyhauOptionValue,
    order,
  }) {
    setIsLoading(true);

    Promise.all([
      postIdentityHdyhau(identityId, {
        quote_id: Store.get(COMPLETE_QUOTE_ID_LS_KEY),
        order,
        option_identifier: hdyhauOptionSelected,
        option_value: hdyhauOptionValue,
        vet_id: inputVet?.id,
      }),

      customSignInToCognito(email, token),
    ])
      .then(() => {
        setIsLoggedIn(true);

        if (inputVet?.id) {
          postUserVet(inputVet.id);
        }

        inputVet?.id || quoteVetId
          ? navigateTo(getMemberRegisterUrl(token))
          : history.push(
              Paths.VetCollection + '?' + new URLSearchParams({ token }).toString()
            );
      })
      .catch(() => {
        history.push(Paths.CheckYourEmail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { submit, isLoading };
}
