import { useRef } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getQuotePartner, useQuote } from '@pumpkincare/quotes';
import { CAT, DOG } from '@pumpkincare/shared';
import { ButtonStyles, KeyboardArrowDown, Typography } from '@pumpkincare/shared/ui';

import InsuranceModal from './insurance-modal';
import Levers from './levers';

import styles from './insurance-picker.module.css';

export default function InsurancePicker({
  petPlanData,
  onChange,
  buttonText = 'Add to Cart',
  onAddToCart,
  pet = {},
}) {
  const { profileModule } = useFlags();

  const { data: quoteData } = useQuote();
  const partner = getQuotePartner(quoteData);

  const postPickerRef = useRef();
  const {
    activeValues,
    insurancePicker: { activePrice, availableLevers, recommendations },
  } = petPlanData;

  const { petBreedSpecies } = pet;

  const modalData = {
    [DOG]: {
      title: 'Reimbursement Rate',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'This is the percentage of an eligible vet bill your pet insurance plan will reimburse you for.',
        },
        {
          title: 'Why 90%?',
          copy:
            'Because we think every dog parent deserves maximum cash back on eligible vet bills for accidents & illnesses. ' +
            'And that means 90% cash back!',
        },
      ],
      footer: {
        title: 'Arf! Arf!',
        copy: '90% cash back on eligible vet bills kicks in after your annual deductible.',
        img: '/assets/images/swimming-cash-dog.png',
      },
    },

    [CAT]: {
      title: 'Reimbursement Rate',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'This is the percentage of an eligible vet bill your pet insurance plan will reimburse you for.',
        },
        {
          title: 'Why 90%?',
          copy:
            'Because we think every cat parent deserves maximum cash back on eligible vet bills for accidents & illnesses. ' +
            'And that means 90% cash back!',
        },
      ],
      footer: {
        title: 'Meow! Meow!',
        copy: '90% cash back on eligible vet bills kicks in after your annual deductible.',
        img: '/assets/images/swimming-cash-cat.png',
      },
    },
  };

  const containerClass = classNames({
    [styles.container]: !profileModule,
    [styles.containerProfile]: profileModule,
  });

  return (
    <div className={containerClass}>
      <div className={styles.topRow}>
        <div className={styles.topText}>
          <div className={styles.headerWrapper}>
            <div className={styles.kidney}>
              <img
                alt=''
                src='/assets/images/paw-huck-blue.svg'
                className={styles.pawHuck}
              />
              <img
                alt=''
                src='/assets/images/kidney-1.svg'
                className={styles.bgImg}
              />

              <div className={styles.percentBox}>
                <span className={classNames(styles.percentBoxTitle, Typography.h1)}>
                  90%
                </span>
                <span className={Typography.legalBody}>
                  reimbursement for all eligible <br />
                  vet bills for accidents & illnesses{' '}
                </span>

                <InsuranceModal modalData={modalData[petBreedSpecies] ?? {}} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Levers
        activeValues={activeValues}
        levers={availableLevers}
        recommendations={
          partner === 'internal-plan-recommendation' ? {} : recommendations
        }
        onChange={onChange}
        pet={pet}
      />

      <button
        className={classNames(ButtonStyles.primary, styles.addToCartButton)}
        data-testid='select-this-plan'
        onClick={onAddToCart}
      >
        {`${buttonText} – $${activePrice} / mo`}
      </button>

      {profileModule ? (
        <>
          <div className={styles.arrowSection}>
            <div
              className={styles.downArrow}
              onClick={() => {
                postPickerRef.current.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <KeyboardArrowDown />
            </div>
          </div>
          <div ref={postPickerRef} />
        </>
      ) : null}
    </div>
  );
}
