import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getQuoteEmail, useQuote } from '@pumpkincare/quotes';
import { Body1, InputStyles, LegalBody, Typography } from '@pumpkincare/shared/ui';
import { getUserShippingAddress, useUssr } from '@pumpkincare/user';

import {
  setUpdatedQuoteBillingField,
  setUpdatedQuoteShippingField,
} from '../../../../quotes';
import {
  getQuotesBillingAddressPhone,
  getQuotesShippingAddress,
} from '../../../../quotes/selectors';
import AddressDisplay from './address-display';
import AddressInput from './address-input';

import styles from './checkout-form.module.css';

function ShippingForm() {
  const dispatch = useDispatch();
  const quotesShippingAddress = useSelector(getQuotesShippingAddress);
  const phoneField = useSelector(getQuotesBillingAddressPhone);

  const { data: quoteData } = useQuote();
  const policyEmail = getQuoteEmail(quoteData);

  const { data: userData } = useUssr();
  const userShippingAddress = getUserShippingAddress(userData);

  const hasShippingAddress = !!userShippingAddress.id;

  return (
    <div className={styles.shippingForm}>
      <LegalBody style={{ margin: '16px 0 0' }}>
        Enter your pet’s full address. The name, state & zip code from your plan have
        already been added. All fields required unless otherwise noted.
      </LegalBody>

      {hasShippingAddress ? (
        <AddressDisplay
          address={userShippingAddress}
          style={{ margin: '16px 0 0' }}
        />
      ) : (
        <>
          <div data-testid='shipping-form'>
            <AddressInput
              address={quotesShippingAddress}
              isShipping
              onChange={payload => dispatch(setUpdatedQuoteShippingField(payload))}
              email={policyEmail}
            />
          </div>

          <Body1 className={styles.inputLabel}>Phone number</Body1>

          <input
            className={classNames(
              styles.textInput,
              Typography.body2,
              styles.border,
              InputStyles.textBorderless,
              phoneField.error ? styles.error : null
            )}
            data-testid='phone-input'
            aria-label='Phone'
            id={'phone'}
            name={'phone'}
            autoComplete={'tel'}
            defaultValue={phoneField.value}
            onChange={e => {
              dispatch(
                setUpdatedQuoteBillingField({
                  field: phoneField.id,
                  value: e.target.value,
                })
              );
            }}
          />
        </>
      )}
    </div>
  );
}

export default ShippingForm;
