// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quote-edit-modal_container_1db0ef18 {\n  background: var(--zeroWhite);\n  max-width: 480px;\n  margin: auto;\n}\n\n.quote-edit-modal_container_1db0ef18 > h3 {\n  text-align: center;\n\n  margin-bottom: 28px;\n}\n\n.quote-edit-modal_spacing_5e074b00 {\n  margin-top: 24px;\n}\n\n.quote-edit-modal_selectedButton_112ca42a {\n  display: flex;\n  height: 144px;\n  gap: 8px;\n}\n\n.quote-edit-modal_selectedButton_112ca42a > button {\n  flex: 1;\n  max-width: unset;\n}\n\n.quote-edit-modal_button_fd7d6a89 {\n  margin-top: 24px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/quote-edit-modal/quote-edit-modal.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;;EAElB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,OAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".container {\n  background: var(--zeroWhite);\n  max-width: 480px;\n  margin: auto;\n}\n\n.container > h3 {\n  text-align: center;\n\n  margin-bottom: 28px;\n}\n\n.spacing {\n  margin-top: 24px;\n}\n\n.selectedButton {\n  display: flex;\n  height: 144px;\n  gap: 8px;\n}\n\n.selectedButton > button {\n  flex: 1;\n  max-width: unset;\n}\n\n.button {\n  margin-top: 24px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "quote-edit-modal_container_1db0ef18",
	"spacing": "quote-edit-modal_spacing_5e074b00",
	"selectedButton": "quote-edit-modal_selectedButton_112ca42a",
	"button": "quote-edit-modal_button_fd7d6a89"
};
export default ___CSS_LOADER_EXPORT___;
